import style from './common.module.css'
import { ChannelName } from '../../../../../_channel/channel-name'
import { CreatorLogo } from '../../../../../_creator'
import { useAppSelector as useSel } from '@root/store'
import { selectChannelPropByChannelPathname as sel } from '@store/channel-slice/_selectors'

export type Props = {
  channelPathname: string
}

export function ChannelBox({ channelPathname }: Props): JSX.Element {
  const logoSrc = useSel(sel(channelPathname)('logo')) ?? ''
  const logoAlt = useSel(sel(channelPathname)('logoAlt'))
  const displayName = useSel(sel(channelPathname)('displayName')) ?? 'Channel'

  return (
    <div className={style.wrapper}>
      <CreatorLogo
        logoSrc={logoSrc}
        logoAlt={logoAlt ?? displayName}
        channelPathname={channelPathname}
        className={style.logo}
        isLink
      />
      <ChannelName channelPathname={channelPathname} isLink={true} className={style.h2} />
    </div>
  )
}
