import { selectContentById, selectContentPropByContentId } from '@store/contents-slice/_selectors'
import { useSelector } from 'react-redux'
import ChannelOwnerRail from '@ui/rails/channel-owner'
import styles from './styles.module.scss'
import RailContentArtwork from '@ui/rails/content-artwork'
import { CONTENT_NAME_PLACEHOLDER } from '@shared/constants/content/placeholders.constants'
import { HOME_PAGE_CHANNEL_PATHNAME } from '@shared/constants/root.constants'
import { ToVideoInfoButton } from '@library/_flows/_checkout-video-flow/to-video-info-button'
import RenderIf from '@utils/render-if'
import { useAppSelector, useAppSelector as useSel } from '@root/store'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'
import { SizesEnum } from '@shared/enums/layout/sizes.enum'
import { BORDER_FALLBACK } from '@shared/constants/theme/colours.constants'
import { useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ContentBadge } from '@ui/content-badge'
import VodAudioDurationBadge from '@ui/duration-badge/vod-audio'
import { generateContentPath } from '@utils/content/generate-content-path'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { isCustomPagePath, returnChannelOrContentPath } from '@utils/custom-pages'

export interface Props {
  contentId: string
  channelPathname: string
}

export default function SecondaryRailCard({ contentId, channelPathname }: Props): JSX.Element {
  const router = useRouter()
  const content = useSelector(selectContentById(contentId))
  const title = content?.title ?? CONTENT_NAME_PLACEHOLDER
  // THEME
  const channelTheme = useSel(selectChannelThemeByChannelPathname(channelPathname))

  // Determinate if we need to render the channel logo
  let showChannelLogo = channelPathname === HOME_PAGE_CHANNEL_PATHNAME

  // Content channel
  const contentChannelUrl = useAppSelector(selectContentPropByContentId(contentId)('channelUrl'))
  if (channelPathname === HOME_PAGE_CHANNEL_PATHNAME) {
    channelPathname = contentChannelUrl
  }

  const isCheerIqFlagActive = useSelector(selectAppFeatureFlagStatusByName('flagCheerdanceIqPage'))
  if (isCheerIqFlagActive) {
    showChannelLogo = isCustomPagePath(channelPathname)
    channelPathname = returnChannelOrContentPath(channelPathname, contentChannelUrl)
  }

  const cardThemeColours: Record<string, unknown> = {
    '--border': channelTheme?.buttonColour ?? BORDER_FALLBACK,
  }

  const contentLink = generateContentPath(contentId, channelPathname)

  useEffect(() => {
    if (router.isReady) {
      router.prefetch(contentLink)
    }
  }, [contentLink, router])

  return (
    <Link href={contentLink}>
      <a
        className={` ${styles.cardRail} ${showChannelLogo ? styles.withChannel : styles.noChannel}`}
        style={cardThemeColours}
      >
        <RenderIf isTrue={showChannelLogo}>
          <ChannelOwnerRail channelPathname={channelPathname} size={SizesEnum.SMALL} />
        </RenderIf>
        <div className={styles.artworkWrapper}>
          <RailContentArtwork contentId={contentId} />
          <ContentBadge contentId={contentId} className={styles.badge} />
          <div className={styles.duration}>
            <VodAudioDurationBadge contentId={contentId} />
          </div>
        </div>
        <h5>{title}</h5>
        <div className={styles.button}>
          <ToVideoInfoButton
            contentId={contentId}
            channelPathname={channelPathname}
            isFullWidth={false}
          />
        </div>
      </a>
    </Link>
  )
}
