import style from './common.module.css'
import type { ReactNode } from 'react'

export type Props = {
  children: ReactNode
  parity: 'even' | 'odd'
}

export default function DoubleBorders({ children, parity }: Props): JSX.Element {
  return (
    // The outer div keeps the width correct in flex box.
    <div className={style.outer}>
      {/* The inner div's width is calculated according to the outer div's width */}
      <div className={[style.inner, style[parity]].join(' ')}>{children}</div>
    </div>
  )
}
