import TextBox from './text-box'
import style from './common.module.css'
import { ToVideoInfoButton } from '../../../_flows/_checkout-video-flow/to-video-info-button'

export type Props = {
  contentId: string
  channelPathname: string
  isShowChannel?: boolean
}

export default function TextButtonBox({
  contentId,
  channelPathname,
  isShowChannel,
}: Props): JSX.Element {
  return (
    <div className={style.wrapper}>
      <TextBox
        contentId={contentId}
        isShowChannel={isShowChannel}
        channelPathname={channelPathname}
      />
      <ToVideoInfoButton contentId={contentId} channelPathname={channelPathname} />
    </div>
  )
}
