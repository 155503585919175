import styles from './styles.module.scss'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function PrimaryRailSkeleton(): JSX.Element {
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <div className={styles.bones}>
        <div className={styles.bone}>
          <Skeleton count={1} height={278} />
        </div>
        <div className={styles.bone}>
          <Skeleton count={1} height={278} />
        </div>
      </div>
    </SkeletonTheme>
  )
}
