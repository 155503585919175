import { filter, flatten, map, pipe, prop, propIs, propOr } from 'ramda'
import type { ContentEntity } from '@apis/contents-microservice/_entities/_types'
import type { FetchStatus } from '../_types'
import type { RailContent, RailEntity } from '@apis/rails-microservice/_types'
import type { RootState } from '..'

export const selectRailPropByRailId =
  (railId: string) =>
  <PropKey extends keyof RailEntity>(propKey: PropKey) =>
  ({ rails }: RootState): RailEntity[PropKey] | undefined => {
    const rail = rails.byRailId?.[railId]

    // If property value is null, return undefined
    return rail?.[propKey] ?? undefined
  }

export const selectStatus = ({ rails }: RootState): FetchStatus => {
  return rails.fetchStatus
}

export const selectErrorMessages = ({ rails }: RootState): string => {
  return rails.errorMessages
}

export const selectContentIdsByRailId =
  (railId: string) =>
  ({ rails }: RootState): string[] | undefined => {
    const railEntity = rails.byRailId?.[railId]
    const getContentsId = pipe<RailEntity[], RailContent[], ContentEntity[], string[]>(
      propOr([], 'railContents'),
      map((railContent) => railContent?.content),
      map((content) => content?.contentId),
    )
    return railEntity === undefined ? undefined : getContentsId(railEntity)
  }

export const selectAllRailIdsByChannelPathname =
  (pathname: string) =>
  ({ rails }: RootState): string[] | undefined =>
    rails.allRailIdsByChannelPathname?.[pathname]
