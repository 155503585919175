import Slider from '@ui/slider'
import { CARD_SLIDER_CONFIG } from './_constants'
import { useAppSelector as useSel } from '@root/store'
import { selectContentIdsByRailId, selectRailPropByRailId } from '@store/rails-slice/_selectors'
import styles from './styles.module.scss'
import { SecondaryCard } from '@library/_cards/secondary-card'

export interface Props {
  railId: string
  channelPathname: string
}

export default function StandardRailV2({ railId, channelPathname }: Props): JSX.Element {
  const { settings, showButtons, buttonsVariant } = CARD_SLIDER_CONFIG
  const contentIds = useSel(selectContentIdsByRailId(railId)) ?? []
  const displayName = useSel(selectRailPropByRailId(railId)('displayName')) ?? ''
  const renderCardRail = contentIds.map((contentId) => (
    <SecondaryCard
      classname={styles.fullWidth}
      contentId={contentId}
      key={contentId}
      channelPathname={channelPathname}
    />
  ))

  return (
    <section className={styles.railCards} aria-label={displayName}>
      <h2 className={styles.title}>{displayName}</h2>
      <Slider
        elements={renderCardRail}
        settings={settings}
        showButtons={showButtons}
        buttonsVariant={buttonsVariant}
        isRailContent
      />
    </section>
  )
}
