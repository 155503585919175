import { selectAllRailIdsByChannelPathname } from '@store/rails-slice/_selectors'
import RenderRail from './rail'
import { useAppSelector, useAppSelector as useSel } from '@store/index'
import { selectStatus } from '@store/channel-slice/_selectors'
import { isGreaterThanZero, parseBoolean } from '@root/utils/connascence'
import RenderIf from '@utils/render-if'
import PrimaryRailSkeleton from '@ui/rails/skeleton/primary'
import { useMemo, useState } from 'react'
import { HALF_SECOND_MS } from '@root/constants'
import styles from './styles.module.scss'

export type Variant = 'default'

export type Props = {
  channelPathname: string
  variant?: Variant
}

export default function Rails({ channelPathname }: Props): JSX.Element {
  const railIds = useSel(selectAllRailIdsByChannelPathname(channelPathname)) ?? []
  const fetchStatus = useAppSelector(selectStatus)
  const [loading, setIsLoading] = useState<boolean>(true)
  //Featured flag
  const isNewTheme = parseBoolean(process.env.VIDZING_THEME_2024)

  useMemo(() => {
    if (fetchStatus === 'succeeded') {
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, HALF_SECOND_MS)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [fetchStatus])

  return (
    <>
      <RenderIf isTrue={isNewTheme && loading}>
        <PrimaryRailSkeleton />
      </RenderIf>
      <RenderIf isTrue={!loading && isGreaterThanZero(railIds.length)}>
        <div className={styles.rails}>
          {railIds.map((railId) => (
            <RenderRail railId={railId} key={railId} channelPathname={channelPathname} />
          ))}
        </div>
      </RenderIf>
    </>
  )
}
