import { useAppSelector as useSel, useAppSelector } from '@root/store'
import {
  selectBundlePropByContentId,
  selectContentPropByContentId,
} from '@store/contents-slice/_selectors'
import useRunFunctionOnce from '@hooks/use-run-function-once'
import { getBundleContentByContentIdThunk } from '@store/bundle-slice/_thunks'
import { useDispatch } from 'react-redux'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import { selectFirstBundleModuleContent } from '@store/bundle-slice/_selectors'
import { DEFAULT_AUTOPLAY_QUERY_OBJECT } from '@shared/constants/root.constants'
import buildPathWithParams from '@utils/build-path-with-params'

interface Props {
  contentId: string
  channelPathname: string
}

export default function useGetBundleLink({ contentId, channelPathname }: Props) {
  const channelId = useSel(selectChannelPropByChannelPathname(channelPathname)('channelId'))
  const bundle = useAppSelector(selectContentPropByContentId(contentId)('bundle'))
  const state = useAppSelector(selectContentPropByContentId(contentId)('state'))
  const bundleId = useSel(selectBundlePropByContentId(contentId)('bundleId'))
  const firstModuleContent = useSel((state) => selectFirstBundleModuleContent(state, contentId))
  const dispatch = useDispatch()

  const isCourseAndPurchased =
    bundle?.bundleType?.typeName === 'course' && state.button === 'purchased'

  useRunFunctionOnce({
    dependencies: [channelId, contentId],
    callback: () => {
      dispatch(getBundleContentByContentIdThunk({ contentId: contentId, channelId: channelId }))
    },
    condition: !!channelId && !!contentId && isCourseAndPurchased,
  })

  if (isCourseAndPurchased && firstModuleContent && bundleId) {
    const baseRoutePath = `/${channelPathname}/${firstModuleContent.contentId}`
    const baseParams = {
      bundle_id: bundleId,
      bundle_content_id: contentId,
      bundle_module_id: firstModuleContent.bundleModuleId,
    }
    const baseParamsWithAutoplay = {
      ...baseParams,
      ...DEFAULT_AUTOPLAY_QUERY_OBJECT,
    }

    return buildPathWithParams(`${baseRoutePath}/watch`, baseParamsWithAutoplay)
  }
  return null
}
