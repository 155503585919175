import style from './common.module.css'
import { CreatorLogo } from '../../../_creator'
import { ContentTitle } from '../../../_content'
import { selectContentPropByContentId as selContent } from '@store/contents-slice/_selectors'
import {
  selectChannelData,
  selectChannelPathnameByChannelId as selChannel,
} from '@store/channel-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { CREATOR_LOGO_WIDTH_THUMB } from '@root/constants'
import { useRouter } from 'next/router'

export type Props = {
  contentId: string
  channelPathname: string
}

export function TextBox({ contentId, channelPathname }: Props): JSX.Element {
  //
  const channel = useSel(selectChannelData(channelPathname))

  /** Validating if we should display the creator logo or not.
   * This condition was added to avoid pass as prop the view */
  const router = useRouter()
  const { pathname } = router as any
  const hideCreatorLogo = pathname && pathname === '/[channel_pathname]'
  //
  return (
    <div className={style.wrapper}>
      {!hideCreatorLogo && (
        <CreatorLogo
          logoSrc={channel?.logo}
          logoAlt={channel?.logoAlt ?? channel?.displayName}
          channelPathname={channelPathname}
          className={style.img}
          width={CREATOR_LOGO_WIDTH_THUMB}
        />
      )}
      <ContentTitle
        hn={'h3'}
        contentId={contentId}
        className={style.title}
        channelPathname={channelPathname}
      />
    </div>
  )
}
