import DoubleBorders from './double-borders'
import style from './common.module.css'
import { Thumbnail } from '../../../_content'
import { RAIL_PRIMARY_WIDTH_THUMB } from '@root/constants'
import ResumePlaybackProgressBar from '@features/_ui/resume-playback-progress-bar'

export type Props = {
  contentId: string
  channelPathname: string
  parity: 'even' | 'odd'
}

export default function ImgBox({ contentId, parity, channelPathname }: Props): JSX.Element {
  return (
    <DoubleBorders parity={parity}>
      <Thumbnail
        contentId={contentId}
        className={style.thumbnail}
        width={RAIL_PRIMARY_WIDTH_THUMB}
      />
      <ResumePlaybackProgressBar contentId={contentId} channelPathname={channelPathname} />
    </DoubleBorders>
  )
}
