import style from './common.module.css'
import { ContentBadge, Thumbnail } from '../../../_content'

// constants
import { RAIL_SECONDARY_WIDTH_THUMB } from '@root/constants'

export type Props = {
  contentId: string
}

export function ImgBox({ contentId }: Props): JSX.Element {
  return (
    <div className={style.wrapper}>
      <Thumbnail
        contentId={contentId}
        className={style.thumbnail}
        width={RAIL_SECONDARY_WIDTH_THUMB}
      />
      <ContentBadge contentId={contentId} className={style.badge} />
    </div>
  )
}
