import styles from './styles.module.scss'
import { DEFAULT_WIDTH_THUMB } from '@root/constants'
import { useAppSelector as useSel } from '@root/store'
import { selectContentPropByContentId as sel } from '@store/contents-slice/_selectors'
import { buildThumbnailImageToUse } from '@utils/image'
import { Img } from '@library/_images/img'
import RenderIf from '@utils/render-if'
import { isEmptyString } from '@utils/connascence'

export interface Props {
  contentId: string
  width?: number
}

export default function RailContentArtwork({
  contentId,
  width = DEFAULT_WIDTH_THUMB,
}: Props): JSX.Element {
  let thumbnailSrc = useSel(sel(contentId)('thumbnail')) ?? ''
  let thumbnailAlt = useSel(sel(contentId)('thumbnailAlt')) ?? ''
  let title = useSel(sel(contentId)('title')) ?? 'Title'

  const thumbnailToUse = buildThumbnailImageToUse(thumbnailSrc, width, '16:9')
  return (
    <div className={styles.railContentArtwork}>
      <RenderIf isTrue={!isEmptyString(thumbnailToUse)}>
        <Img alt={thumbnailAlt || title} src={thumbnailToUse} aspectRatio={'16/9'} />
      </RenderIf>
    </div>
  )
}
