import { useSelector } from 'react-redux'
import { selectContentById } from '@store/contents-slice/_selectors'
import { isEmptyArray } from '@utils/arrays'
import { useMemo } from 'react'
import { BUY_NOW, FREE, PRICES_FROM } from '@shared/constants/content/placeholders.constants'
import { toDollar } from '@library/_utilities/to-dollar'
import { currencySymbol } from '@library/_utilities/to-display-currency'

export default function useContentButtonWithPrice(contentId: string) {
  const content = useSelector(selectContentById(contentId))

  return useMemo(() => {
    if (!isEmptyArray(content?.contentPrices)) {
      const currency = content?.contentPrices[0]?.currency?.toUpperCase() ?? ''
      const sortPrices = content?.contentPrices.map((prices) => prices.price).sort((a, b) => a - b)

      // Free content
      if (sortPrices[0] === 0) {
        return FREE
      } else {
        return ` ${sortPrices.length > 1 ? PRICES_FROM : BUY_NOW} ${currencySymbol(
          currency,
        )}${toDollar(sortPrices[0])} ${currency}`
      }
    } else {
      return BUY_NOW
    }
  }, [content?.contentPrices])
}
