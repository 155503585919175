import style from './styles.module.scss'
import { ChannelName } from '@library/_channel/channel-name'
import { CreatorLogo } from '@library/_creator'
import { CARD_WIDTH_THUMB } from '@root/constants'
import { useAppSelector as useSel } from '@root/store'
import {
  selectChannelPropByChannelPathname,
  selectChannelThemeByChannelPathname,
  selectSubChannelData,
} from '@store/channel-slice/_selectors'
import { PREVIEW_CHANNEL_AVATAR } from '@shared/constants/root.constants'

export type Props = {
  channelPathname: string
  mainChannelName?: string
}

export function CreatorRailLogo({ channelPathname, mainChannelName }: Props): JSX.Element {
  let logo = useSel(selectChannelPropByChannelPathname(channelPathname)('logo')) ?? ''
  let logoAlt = useSel(selectChannelPropByChannelPathname(channelPathname)('logoAlt')) ?? ''
  let displayName = useSel(selectChannelPropByChannelPathname(channelPathname)('displayName')) ?? ''

  // Checks if parent is a super channel and get sub channel data
  const subChannelData = useSel(selectSubChannelData(channelPathname, mainChannelName))

  if (subChannelData) {
    logo = subChannelData.logo
    logoAlt = subChannelData.logoAlt
    displayName = subChannelData.displayName
  }

  // THEME
  const channelTheme = useSel(selectChannelThemeByChannelPathname(mainChannelName))
  const cardThemeColours: Record<string, unknown> = {
    '--border': channelTheme?.buttonColour ?? 'var(--foregroundColor)',
  }

  const logoToRender = logo !== undefined ? logo : PREVIEW_CHANNEL_AVATAR

  return (
    <div className={style.creatorLogo} style={cardThemeColours}>
      <div className={style.wrapper}>
        <CreatorLogo
          logoSrc={logoToRender}
          logoAlt={logoAlt ?? displayName}
          channelPathname={channelPathname}
          className={style.logo}
          wrapperClassName={style.logoWrapper}
          isLink={true}
          variant={'card'}
          width={CARD_WIDTH_THUMB}
        />
        <ChannelName
          channelPathname={channelPathname}
          mainChannelName={mainChannelName}
          isLink={true}
          className={style.name}
        />
      </div>
    </div>
  )
}
