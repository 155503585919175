import { selectContentById } from '@store/contents-slice/_selectors'
import { useSelector } from 'react-redux'
import { isEmptyString, removeHtmlTags, truncateTextWithEllipsis } from '@utils/strings'

export default function useContentDescription(contentId: string) {
  const content = useSelector(selectContentById(contentId))

  const descriptionToUse =
    !isEmptyString(content?.shortDescription) && content?.shortDescription !== null
      ? content?.shortDescription
      : content?.description

  if (!isEmptyString(descriptionToUse) && descriptionToUse !== null) {
    return removeHtmlTags(descriptionToUse)
  }

  return ''
}
