import { Button } from '../../../_buttons'
import { CheckedOutIcon } from '../../_icons'
import { useUpdateUserContentState } from '../_hooks/use-update-user-content-state'
import { useToVideoInfoButtonHandleNext } from './_hooks/use-to-video-info-button-handle-next'
import { useCheckoutVideoFlowState } from '../_hooks/use-checkout-video-flow-state'
import { useAppSelector as useSel } from '@root/store'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'
import useContentButtonWithPrice from '@hooks/_content/use-content-button-with-price'

export type Props = {
  contentId: string
  isFullWidth?: boolean
  channelPathname: string
  className?: string
}

/**
 * This button is on the primary rails. It's main purpose is to guide the user to the video info page.
 * If the user has purchased, disable this button, or link to player page.
 * @param contentId
 * @param className
 * @param channelPathname
 * @constructor
 */
export function ToVideoInfoButton({ contentId, className, channelPathname }: Props): JSX.Element {
  useUpdateUserContentState(contentId)
  const checkoutVideoFlowState = useCheckoutVideoFlowState(contentId)
  const handleNext = useToVideoInfoButtonHandleNext({
    buttonState: checkoutVideoFlowState,
    contentId,
    channelPathname,
  })
  const channelTheme = useSel(selectChannelThemeByChannelPathname(channelPathname))
  //
  const buyNowPriceToShow = useContentButtonWithPrice(contentId)

  switch (checkoutVideoFlowState) {
    case 'ticketedOnly':
    case 'accessRestricted':
    case 'findOutMore':
    case 'subscribe':
      return (
        <Button onClick={handleNext} className={className} channelTheme={channelTheme}>
          Find out more
        </Button>
      )
    case 'buyNow':
      return (
        <Button onClick={handleNext} className={className} channelTheme={channelTheme}>
          {buyNowPriceToShow}
        </Button>
      )
    case 'bookNow':
      return (
        <Button onClick={handleNext} className={className} channelTheme={channelTheme}>
          Book now
        </Button>
      )
    case 'purchased':
      return (
        <Button Icon={CheckedOutIcon} className={className} channelTheme={channelTheme}>
          You&apos;re all set!
        </Button>
      )
    case 'booked':
    case 'channelAccessState':
      return (
        <Button Icon={CheckedOutIcon} className={className} channelTheme={channelTheme}>
          You&apos;re all set!
        </Button>
      )
    case 'subscribed':
      return (
        <Button Icon={CheckedOutIcon} className={className} channelTheme={channelTheme}>
          Subscribed
        </Button>
      )
    case 'watchNow':
      return (
        <Button onClick={handleNext} className={className} channelTheme={channelTheme}>
          Watch now
        </Button>
      )
    case 'joinNow':
      return (
        <Button onClick={handleNext} className={className} channelTheme={channelTheme}>
          Join now
        </Button>
      )
    case 'listenNow':
      return (
        <Button onClick={handleNext} className={className} channelTheme={channelTheme}>
          Listen now
        </Button>
      )
    case 'geoBlocked':
    case 'loading':
    case 'expired':
    default:
      return <></>
  }
}
