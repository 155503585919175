import contentTitleStyle from './content-title.module.css'
import style from './common.module.css'
import { ContentTitle } from '@library/_content'
import { ChannelBox } from './channel-box'
import { selectContentPropByContentId as selContent } from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import ContentSchedule from '@features/_ui/content-schedule'

export type Props = {
  contentId: string
  channelPathname: string
  isShowChannel?: boolean
}

export default function TextBox({
  contentId,
  isShowChannel = false,
  channelPathname,
}: Props): JSX.Element {
  const shortDescription = useSel(selContent(contentId)('shortDescription'))

  return (
    <article className={style.article}>
      {isShowChannel && channelPathname && <ChannelBox channelPathname={channelPathname} />}
      <ContentTitle
        hn="h3"
        className={[
          style.contentTitle,
          contentTitleStyle[isShowChannel ? 'isShowChannel' : 'isHideChannel'],
        ].join(' ')}
        contentId={contentId}
        channelPathname={channelPathname}
      />
      <ContentSchedule contentId={contentId} />
      <p>{shortDescription}</p>
    </article>
  )
}
