import type { RailType } from '@apis/rails-microservice/_types'
import { selectRailPropByRailId as sel } from '@store/rails-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import RailCards from '@ui/rails/primary-cards'
import SecondaryRailCards from '@ui/rails/secondary-cards'
import FeatureRailV2 from '@ui/rails/feature-rail'
import StandardRailV2 from '@ui/rails/standard-rail'

export type Props = {
  railId: string
  channelPathname: string
}

export default function RenderRail({ railId, channelPathname }: Props): JSX.Element {
  const railType = useSel(sel(railId)('railType'))

  const elementMap: Record<RailType, JSX.Element> = {
    primary: <FeatureRailV2 railId={railId} channelPathname={channelPathname} />,
    secondary: <StandardRailV2 railId={railId} channelPathname={channelPathname} />,
    ['card_primary']: <RailCards railId={railId} channelPathname={channelPathname} />,
    ['card_secondary']: <SecondaryRailCards railId={railId} channelPathname={channelPathname} />,
  }

  return <>{railType && elementMap[railType]}</>
}
