import { SliderSettingsInterface } from '@shared/interfaces/layout/slider.interface'
import { SliderButtonStylesEnum } from '@shared/enums/slider.enum'

export const CARD_SLIDER_CONFIG: SliderSettingsInterface = {
  showButtons: true,
  showButtonsInMobile: true,
  buttonsVariant: SliderButtonStylesEnum.BOX,
  settings: {
    navigation: false,
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 8,
    freeMode: true,
    loop: false,
    pagination: {
      clickable: true,
      showPagination: false,
    },
    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      653: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
      2300: {
        slidesPerView: 7,
      },
    },
  },
}
