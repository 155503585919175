import { useEffect, useRef } from 'react'

interface UseDispatchOnceProps<T> {
  dependencies: Array<T>
  condition: boolean
  callback: () => void
}

/**
 * Dispatch an action once
 * @param dependencies
 * @param condition
 * @param callback
 */
export default function useRunFunctionOnce<T>({
  dependencies,
  condition,
  callback,
}: UseDispatchOnceProps<T>) {
  const isLoaded = useRef(false)

  useEffect(() => {
    if (condition && !isLoaded.current) {
      callback()
      isLoaded.current = true
    }
  }, [callback, condition, dependencies])
}
