import { useSelector } from 'react-redux'
import {
  selectChannelData,
  selectChannelThemeByChannelPathname,
  selectStatus,
} from '@store/channel-slice/_selectors'
import ChannelLogo from '@ui/channel/logo'
import { PREVIEW_CHANNEL_AVATAR } from '@shared/constants/root.constants'
import styles from './style.module.scss'
import { SizesEnum } from '@shared/enums/layout/sizes.enum'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { UNNAMED_CHANNEL } from '@shared/constants/channel/channel.contants'
import { useAppSelector as useSel } from '@root/store'
import { BORDER_FALLBACK } from '@shared/constants/theme/colours.constants'

export interface Props {
  channelPathname: string
  size?: SizesEnum
}
export default function ChannelOwnerRail({
  channelPathname,
  size = SizesEnum.MEDIUM,
}: Props): JSX.Element {
  const router = useRouter()
  const channel = useSelector(selectChannelData(channelPathname))
  const channelTheme = useSel(selectChannelThemeByChannelPathname(channelPathname))

  const highlightColour: Record<string, unknown> = {
    '--highlight': channelTheme?.buttonColour ?? BORDER_FALLBACK,
  }

  const logo = channel?.logo ?? PREVIEW_CHANNEL_AVATAR
  const name = channel?.displayName ?? UNNAMED_CHANNEL

  const isUnnamedChannel = name === UNNAMED_CHANNEL

  const redirectTo = () => {
    if (!isUnnamedChannel) {
      router.push(`/${channelPathname}`)
    }
  }

  useEffect(() => {
    if (!isUnnamedChannel && router.isReady) {
      router.prefetch(`/${channelPathname}`)
    }
  }, [channelPathname, isUnnamedChannel, router])

  return (
    <button
      className={`${styles.channelOwner} ${styles[size]}`}
      onClick={redirectTo}
      style={highlightColour}
    >
      <ChannelLogo avatar={logo} alt={name} />
      <span>{name}</span>
    </button>
  )
}
